html{
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #cae0de;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
}

header {
  background-color: #015367;
  color: #fff;
  text-align: center;
  padding: 0.5em 0;
  width: 100%;
}
header h1{
margin: 0!important;   
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  padding: 10px;
  font-size: 16px;
  width: 431px;
  text-align: center;            
  border: 1px solid #ccc;
  /* margin-bottom: 20px; */
}
input[type="checkbox"]{ 
  margin-top: 10px;
  padding: 6px!important;
  text-align: left;
  width: 20px;
}
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1em 0;
}
.main-img{
  width: 450px;
}
/* Медиа-запросы для масштабируемости на мобильных устройствах */
@media (max-width: 600px) {
  input {
      width: 100%;
  }
}
.main{
  text-align: center;
}
.common-reports {
  max-width: 50%; /* Измените значение по вашему усмотрению */
  margin: 0 auto; /* Это центрирует блок по горизонтали */
  padding: 20px; /* Добавляет небольшой внутренний отступ */
}
.help-text{
  padding: 10px;
  text-align: left;
  border:1px solid #015367;
  border-radius: 5px;
}