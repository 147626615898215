.modal {
    display: block; /* Change this to block */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .modal-content {
    margin: 5% auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  .close {
    color: #ccc;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  .modal-image{
    max-width: 300px;
  }