.styled-table {
    background-color: #fff;
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .styled-table th, .styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
  }
  
  .styled-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  .pet-icon{
    width: 70px;
    border-radius: 50px;
  }
  .inner-table tr td{
    border: none;
  }